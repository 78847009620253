<template>
    <span :class="containerClass">
        <span :class="iconClass" v-if="icon"></span>
        <slot>
            <span class="p-tag-value">{{value}}</span>
        </slot>
    </span>
</template>

<script>
export default {
    props: {
        value: null,
        severity: null,
        rounded: Boolean,
        icon: String
    },
    computed: {
        containerClass() {
            return ['p-tag p-component', {
                'p-tag-info': this.severity === 'info',
                'p-tag-success': this.severity === 'success',
                'p-tag-warning': this.severity === 'warning',
                'p-tag-danger': this.severity === 'danger',
                'p-tag-rounded': this.rounded
            }];
        },
        iconClass() {
            return ['p-tag-icon', this.icon];
        }
    }
}
</script>

<style>
.p-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.75rem;
    font-weight: 600;
    border-radius: 0.25rem;
    background-color: #007ad9;
    color: white;
}

.p-tag-info {
    background-color: #17a2b8;
    color: white;
}

.p-tag-success {
    background-color: #28a745;
    color: white;
}

.p-tag-warning {
    background-color: #ffc107;
    color: black;
}

.p-tag-danger {
    background-color: #dc3545;
    color: white;
}

.p-tag-icon,
.p-tag-value,
.p-tag-icon.pi {
    line-height: 1.5;
}

.p-tag.p-tag-rounded {
    border-radius: 10rem;
}
</style>