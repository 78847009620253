<template lang="pug">
Dialog.dialog-pacotes(:modal="true" header= "Agendar Procedimento" :visible.sync="show" :contentStyle="{overflowY: 'auto !important'}")
    .ta-center(v-if='waiting')
        ProgressBar(strokeWidth='6')
    div(v-else)
        form
            .p-col-12(v-if='procedimentos.length')
                DataTable(style='max-height:360px;overflow-y:auto' :value='procedimentos')
                    Column(header='Procedimentos' field='label' bodyStyle='padding: 0')
                        template(#body='props')
                            .pa-1(style='cursor:pointer;font-size:12px;display:flex;align-items:center' @click='selectProcedimento(props.data)')
                                .mr-1(style='display:inline;color:green;font-weight:700' v-if="agendados.find(i => +i.procedimento == props.data.cd_procedimento)")
                                    i.jam.jam-check
                                .mr-1(style='display:inline;color:red;font-weight:700' v-else)
                                    i.jam.jam-close
                                p.lista-procedimentos(:class="{ selecionado: selectedProcedimento == props.data.cd_procedimento }") {{ props.data.nm_procedimento }}
            
            .p-col-12(v-if="selectedProcedimento")
                label.form-label(style='position:relative')
                    span Agendas:
                ProgressBar(v-if="waitingAgendas" mode="inderteminate" )
                p.ta-center(v-else-if='!options.agendas.length' style='font-size: 12px;') <b>Sem agendas disponiveis.</b>
                DataTable.datatable-epecialistas(v-else v-show='options.agendas.length' :value='options.agendas'
                    dataKey='id' :selection.sync="selectedAgenda")
                    Column(selectionMode='single' bodyStyle='text-align: center;' headerStyle='width: 1em')
                    Column(headerStyle='width:90%' field='text' header='Especialista')
                        template(#body='props')
                            div.integracaoConsultasContainer
                                //- div.integracaoSimbolo(v-if="props.data.possui_integracao == true")
                                //-     i(style="color:#db7900" class="jam jam-bookmark-plus-f") &nbsp;
                                div
                                    div(style="display: flex; align-items: center; justify-content: flex-start;")
                                        //- div(v-tooltip.top='"Consulta sem retorno"' style="display: flex; align-items: end")
                                        //-     ForbiddenReturn(v-if='!props.data.ie_permite_retorno')
                                        span <b>{{ props.data.nm_especialista }}</b><br/>
                                    span(style='white-space: pre-wrap') {{ props.data.ds_observacao }}
            
            .p-col-12(v-if='selectedAgenda')
                label.form-label Data:
                ProgressBar(v-if="waitingDatas" mode="inderteminate" strokeWidth='6')
                p.ta-center(v-else-if='!options.datas.length' style='font-size: 12px;') <b>Sem data disponível.</b>
                div(v-else style="position:relative")
                    Calendar(
                        locale='pt-BR'
                        :first-day-of-week='-7'
                        :attributes="[ { highlight: true, dates: selectedData } ]"
                        :available-dates='options.datas'
                        @dayclick='onDayClick'
                    )
                    span(@click='selectedData=null;' v-if="selectedData" style='text-align:center;display:block;width:100%;color:#657786;cursor:pointer;font-size:.88em') limpar data
            
          
            .p-col-12(v-if='selectedData')
                label.form-label Horários Disponíveis:
                ProgressBar(v-if="waitingHorarios" mode="inderteminate" strokeWidth='6')
                p.ta-center(v-else-if='!options.horarios.length' style='font-size: 12px;') <b>Sem horário disponível.</b>
                div(v-else)
                    div(style="margin-bottom:10px;border-bottom:1px solid #eee;padding-bottom:10px;")
                        p
                            strong Turno: &nbsp;
                            span.red-chip-turno {{options.horarios[0].hr_inicio}} às {{options.horarios[0].hr_fim}}

                        div.text-caption.ta-right.my-1
                            div.ll <div class='caption tl'></div> Livre
                            div.ll <div class='caption fe'></div> Encaixado
                            div.ll <div class='caption b0'></div> Bloqueado
                            div.ll <div class='caption to'></div> Ocupado

                        SelectButton.hr_horario(
                            v-model='selectedHorario'
                            :options='options.horarios'
                            optionLabel='hr_agenda'
                            optionValue='id'
                            optionDisabled='disabled'
                            dataKey='id'
                        )
                            template(#option='props')
                                div(style='font-size: 0' v-tooltip.top="props.option.ds_motivo_bloqueio")
                                    div.status(:class="{ disabled: props.option.disabled, bloqueado: props.option.status == 'B', encaixado: props.option.ie_encaixado}")
                                    div.text {{ props.option.hr_agenda }}
                 
            //- .ta-center(v-if='agendados.length' style="margin-top:10px")
            .ta-center(style="margin-top:10px")
                ProgressSpinner(v-if="$parent.waitingCadastroAgenda" strokeWidth='6' :style="{'width': '20px','height': '20px'}")
                Button(v-else type="button" label="Adicionar ao carrinho" @click='handleSubmit' style='width: 100%')

</template>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Calendar from 'v-calendar/lib/components/calendar.umd'
    import Tooltip from "primevue/tooltip"
    import SelectButton from 'primevue/selectbutton'

    import { 
        Agenda, 
        // Agendamento 
    } from '../../middleware';
    import moment from 'moment'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Dialog, SelectButton,
            InputMask, DataTable, Column, ProgressSpinner, Calendar
        },
        directives: { tooltip: Tooltip },
        watch: {
            "model": function(value){
                this.clearData();
                if(value) this.getDatas();
            },
            "options.datas": function(){
                if(!this.agendados.find(item => item.procedimento == this.selectedProcedimento)){
                    this.options.especialistas = [],
                    this.options.horarios = []
                    this.selectedEspecialista = null
                    // this.selectedAgenda = null,
                    this.selectedHorario = null
                }
            },
            "selectedEspecialista": function(val){
                if(val)
                    this.options.horarios = 
                        this.procedimentos.find(item => item.id == this.selectedProcedimento).datas[val.data][val.id].horarios
            },
            "selectedAgenda": function(val){
                if(val) {
                    this.selectAgenda(val)
                }
            },  
            "selectedHorario": function(val){
                if(val){
                    this.inserirAgendado();
                }else
                    if(this.selectedEspecialista) 
                        this.agendados = this.agendados.filter(item => item.procedimento != this.selectedProcedimento) 
            }
        },
        props: [
            'display',
            'model',
            'cd_atendimento',
            'paciente',
            "agendaPacotes",
            "waitingCadastroAgenda"
        ],
        computed: {
            show: {
                get() { 
                    return this.display; 
                },
                set(value) { if (!value) this.$emit('close'); }
            },
        },
        data () {
            return {
                waiting: false,
                waitingForm: false,
                waitingProcedimentos: false,
                waitingAgendar: false,
                waitingAgendas: false,
                waitingDatas: false,
                waitingHorarios: false,
                submitted: false,
                agendados: [],
                options: {
                    datas: [],
                    agendas: [],
                    especialistas: [],
                    horarios: []
                },
                procedimentos: [],
                selectedAgenda:null,
                selectedProcedimento:null,
                selectedData: null,
                selectedEspecialista: null,
                selectedHorario: null
            }
        },
        methods: {
            verificaObservacoes() {
                let aux = this.procedimentos.find(item => item.ds_informacoes)
                if (aux) return true
                return false
            },
            getDatas(){
                this.procedimentos = this.model.itens_pacote.filter(item => item.ie_hora_marcada)
                // this.procedimentos = this.model.itens_pacote
            },
            async onDayClick (ev) {
				let dateChecked = false
				this.options.datas.forEach(data => { if (moment(data).format('YYYY-MM-DD') == ev.id) dateChecked = true })
                try{
                    this.waitingHorarios = true;
                    const response = await Agenda.getHorariosDisponiveis(this.selectedAgenda.id, { dt_agenda: ev.id })
                    this.waitingHorarios = false;
                    if(([200,201,204]).includes(response.status)){
                        this.options.horarios = response.data.filter(i => i.ie_status == "L");
                        if (dateChecked) this.selectedData = moment(ev.id)._d
                    }
                }catch(e){
                    this.$toast.error("Erro em horarios")
                    console.log(e)
                }
			},
            async selectProcedimento(procedimento){
                let auxIndex = this.agendados.findIndex(item => item.procedimento == procedimento.cd_procedimento)
                this.selectedProcedimento = procedimento.cd_procedimento;
                let params = {
                    cd_estabelecimento: procedimento.cd_estabelecimento,
                    cd_procedimento_estabelecimento: procedimento.cd_procedimento_estabelecimento
                }
                try{
                    this.waitingAgendas = true;
                    const response = await Agenda.findAllPacote(params)
                    this.waitingAgendas = false;
                    if(![200, 201, 204].includes(response.status)) 
						throw new Error(response.data.detail)
                    this.options.agendas = procedimento.cd_agenda? 
                        response.data.filter(item => procedimento.cd_agenda.includes(item.id)):response.data;
                    if(auxIndex != -1){
                        await this.onDayClick({id: moment(this.agendados[auxIndex].data).format("YYYY-MM-DD")})
                        this.selectedAgenda = this.agendados[auxIndex].agenda
                        this.selectedHorario = this.agendados[auxIndex].horario
                    }else {
                        this.selectedAgenda = null;
                        this.selectedData = null;
                        this.options.datas = [];
                    }
                }catch(e){
                    console.log(e)
                }
            },
            async selectAgenda(agenda){
                this.selectedData = null;
                this.waitingDatas = true;
                try{
                    const response = await Agenda.getDatasDisponiveis(agenda.id)
                    this.waitingDatas = false;
                    this.options.datas = response.data.map(item => (item.dt_agenda))
                    window.scrollTop = window.scrollHeight;
                }catch(e){
                    console.log(e)
                    this.$toast.error("Erro em Agenda")
                }
            },
            inserirAgendado(){
                let auxIndex = this.agendados.findIndex(item => item.procedimento == this.selectedProcedimento)
                if(auxIndex != -1){
                    this.agendados[auxIndex] = {
                        procedimento:this.selectedProcedimento,
                        data: this.selectedData,
                        agenda: this.selectedAgenda,
                        horario: this.selectedHorario 
                    }
                }
                else
                    this.agendados.push({
                        procedimento:this.selectedProcedimento,
                        data: this.selectedData,
                        agenda: this.selectedAgenda,
                        horario: this.selectedHorario 
                    })
            },
            clearData(){
                this.submitted = false,
                this.agendados = [],
                this.options = {
                    datas: [],
                    especialistas: [],
                    horarios: [],
                    agendas: []
                },
                this.procedimentos = [],
                this.selectedProcedimento = null,
                this.selectedData = null,
                this.selectedEspecialista = null,
                this.selectedHorario = null
                this.selectedAgenda = null;
            },
            async handleSubmit(){
                let dataSend = this.agendados.length? {
                    cd_atendimento: this.cd_atendimento,
                    cd_pessoa_fisica: this.paciente.id,
                    ie_reservar: "R",
                    cd_procedimentos_estabelecimentos: this.agendados? this.procedimentos.map(item => {
                        let auxAgendado = this.agendados.find(i => i.procedimento == item.cd_procedimento)
                        return {
                            cd_especialidade: auxAgendado.agenda.cd_especialidade,
                            id: +item.cd_procedimento_estabelecimento,
                            horario_id: auxAgendado?.horario
                        }
                    }) : []
                } : false
                this.$emit("agendaPacotes", dataSend)
            }
        },
         
        
    }
</script>

<style lang="scss">

.dialog-pacotes {
    width: 50%;
    .container {
        display: flex;
        flex-direction: column;
    }
    .p-dialog-content {
        padding-bottom: 6px;
    }
    .confirm-button {
        background-color: green;
    }
    .cancel-button {
        background-color: red;
    }
    .p-button.p-button-text {
        color: white;
        border-color: transparent;

        &:hover {
            background-color: transparent;
            color: black;
            border-color: black;
        }
    }
    .datatable-epecialistas {
        max-height: 400px; overflow-y: auto;
        td { font-size: 13px; }
    }
    .integracaoConsultasContainer {
        display: flex;
        .integracaoSimbolo {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .semRetornoSimbolo {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .lista-procedimentos {
        &.selecionado {
            color: blue;
        }
    }
    .text-caption {
        font-size: 16px;
        .ll{
            display: inline-block;
            margin-right:20px;
        }
        .caption {
            display: inline-block;
            height: 16px;
            width: 16px;
            background-color: #888;
            margin-right: 5px;
            border-radius: 10px;
            &.sa { background-color: #718096; }
            &.tl { background-color: #38a169; }
            &.hv { background-color: #d69e2e; }
            &.to { background-color: #f7de08; }
            &.b0 { background-color: #e53e3e; }
            &.fe { background-color: #9b67d5; }
        }
        .p-selectbutton {
			&.ie_tipo_agenda {
				.p-button { width: 25% !important; }
			}
			&.ie_tipo_atendimento {
				.p-button { width: 50% !important; }
			}
			&.hr_horario {
				.status {
					display: inline-block;
					vertical-align: middle;
					width: 16px;
					height: 16px;
					border-radius: 50%;
					margin-right: 10px;
					background-color: #38a169;
					border: 1px solid #298051;
					&.encaixado {
						background-color: #9b67d5 !important;
						border: 1px solid #6d40a0 !important;
					}
					&.bloqueado {
						background-color: #e53e3e !important;
						border: 1px solid #c73434 !important;
					}
					&.disabled {
						background-color: #f7de08;
						border: 1px solid #d3be00;
					}
				}
				.text {
					display: inline-block;
					vertical-align: middle;
					font-size: 14px;
					font-weight: 700;
				}
				.p-button {
					padding: 6px;
					width: 20% !important;
				}
			}
			.p-button {
				.p-button-text {
					font-size: 12px;
				}
			}
		}
    }
}
</style>