<template lang="pug">
Dialog.dialog-observacoes(:modal="true" header= "Ver observações" :visible.sync="show" :contentStyle="{overflowY: 'auto !important'}")
    form(header="Ver observações" )
        .p-grid.p-fluid.p-align-top.p-justify-left.p-ml-2
            span(v-if='!verificaObservacoes()') Nenhuma observação foi encontrada.
            div.container(v-else)
                span(v-for='item, idx in procedimentos' :key='idx')
                    p(v-if='item.ds_informacoes')
                        b {{ idx+1 }}. &nbsp;
                            span {{ item.ds_observacao }}
                        br
                        span {{ item.ds_informacoes }}
                        br
</template>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Dialog, InputMask, DataTable, Column,
        },
        props: [
            'display',
            'procedimentos',
        ],
        computed: {
            show: {
                get() { 
                    return this.display; 
                },
                set(value) { if (!value) this.$emit('close'); }
            },
        },
        created () {

        },
        data () {
            return {
                waiting: true,
                waitingForm: false,
                submitted: false,
            }
        },
        methods: {
            verificaObservacoes() {
                let aux = this.procedimentos.find(item => item.ds_informacoes)
                if (aux) return true
                return false
            }
        },
    }
</script>

<style lang="scss">

.dialog-observacoes {
    width: 600px;
    .container {
        display: flex;
        flex-direction: column;
    }
    .p-dialog-content {
        padding-bottom: 6px;
    }
    .confirm-button {
        background-color: green;
    }
    .cancel-button {
        background-color: red;
    }
    .p-button.p-button-text {
        color: white;
        border-color: transparent;

        &:hover {
            background-color: transparent;
            color: black;
            border-color: black;
        }
    }
}
</style>