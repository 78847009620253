<template>
    <div class="origem-component">
        <label class="form-label">Origem:</label>
        <div class="p-inputgroup">
            <CustomDropdown
                ref="customDropdown"
                v-model="origem"
                optionLabel="ds_origem"
                optionValue="id"
                dataKey="id"
                placeholder="- Selecione -"
                :filter="true"
                :options="origens"
                :loadingOptions="waiting"
                :loadingMore="waitingNextPage"
                :filterFunction="getOrigens"
                @end-scroll="onEndScroll"
                @input="$emit('input', origem)"
                @changeFilter="(val) => dsOrigem = val"
            ></CustomDropdown>
            <Button icon="jam jam-plus" @click="dialogCriarOrigem.visible=true" v-tooltip.top="'Cadastrar nova origem'"></Button>
        </div>
        <Dialog class="dialog-criar-origem" header="Cadastrar Origem" :visible.sync="dialogCriarOrigem.visible" :modal="true">
            <div class="p-fluid my-4"><label class="form-label">Origem *:</label>
                <InputText v-model="dialogCriarOrigem.data.ds_origem"></InputText>
            </div>
            <div class="ta-center my-4">
                <ProgressSpinner class="waiting-origem" strokeWidth="6" v-if="dialogCriarOrigem.waiting" />
                <Button class="p-button" label="Cadastrar" @click="criarOrigem()" v-else />
            </div>
        </Dialog>
    </div>
</template>

<script>
import Button from "primevue/button";
import CustomDropdown from "./../CustomComponents/CustomDropdown.vue";
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import Tooltip from "primevue/tooltip";

import { Agendamento } from '../../middleware';

export default {
    components: { Button, CustomDropdown, Dialog, InputText, ProgressSpinner },
    directives: { tooltip: Tooltip },
    props: { value: null },
    created() { this.getOrigens(); },
    data() {
        return {
            origens: [],
            origem: null,
            page: 1,
            lastPage: false,
            dsOrigem: '',
            waiting: false,
            waitingNextPage: false,
            dialogCriarOrigem: {
                data: { ds_origem: '' },
                visible: false,
                waiting:false
            },
        };
    },
    methods: {
        criarOrigem() {
            if (!this.dialogCriarOrigem.data.ds_origem) {
                this.$toast.error("Campo origem é obrigatório.")
                return
            }

            this.dialogCriarOrigem.waiting = true
            Agendamento.origemAtendimentoSave(this.dialogCriarOrigem.data).then(response => {
                this.dialogCriarOrigem.waiting = false
                if ([200, 201, 204].includes(response.status)) {
                    this.$toast.success("Cadastro salvo com sucesso.");
                    this.dialogCriarOrigem.visible = false;
                    this.getOrigens(this.dsOrigem);
                } else if (response.data.detail) {
                    if (typeof response.data.detail === 'string')
                        this.$toast.error(response.data.detail, { duration: 3000 });
                    else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                }
            })
        },
		limpar() {
			this.$refs.customDropdown.filterValue = "";
			this.origem = null;
			this.getOrigens();
		},
        getOrigens(dsOrigem, page) {
            if (dsOrigem && dsOrigem.trim().length < 2) return;

            dsOrigem = dsOrigem || "";
            this.page = page || 1;

            let params = { ds_origem: dsOrigem.trim(), page: this.page };

            if (this.page == 1) this.lastPage = false;

            let waitingKey = (this.page === 1) ? "waiting" : "waitingNextPage";
            this[waitingKey] = true;

            Agendamento.origemAtendimentoFindAllClean(params).then(response => {
                this[waitingKey] = false;
                if (response.status === 200) {
                    if (this.page === 1) this.origens = response.data;
                    else this.origens.push(...response.data);
                    if (response.data.length < 30) this.lastPage = true;
                }
            })
        },
        onEndScroll(filterValue) {
            if (!this.waiting && !this.waitingNextPage && !this.lastPage) {
                this.getOrigens(filterValue, ++this.page);
            }
        }
    },
}
</script>

<style lang="scss">
.origem-component {
    .dialog-criar-origem {
        width: 400px;
        max-width: 96%;
    }
    .waiting-origem {
        width: 25px;
        height: auto;
        margin: 16px 0;
    }
}
</style>
