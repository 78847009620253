<template lang="pug">
Dialog.dialog-agendaLL(:modal="true" header= "Escolha um Horário" :visible.sync="show" :contentStyle="{overflowY: 'auto !important'}")
    .ta-center(v-if='waiting')
        ProgressBar(strokeWidth='6')
    div(v-else)
        form
            label.form-label Procedimentos:
            DataTable.procedimentos-datatable(@row-select='getDatas()' :value='procedimento.procedimentos' dataKey='id' :selection.sync="selectedProcedimento")
                Column(headerStyle='width:7%' selectionMode='single' bodyStyle='text-align: center;')
                Column(headerStyle='width:70%' header='Procedimento')
                    template(#body='props')
                        p.aux1(v-if='props.data.ds_observacao')
                            b  {{ props.data.ds_observacao }}
                            span(v-if='props.data.nm_subgrupo')
                                b.credito-medclub &nbsp; | SUBGRUPO: &nbsp;
                                b {{ props.data.nm_subgrupo }}
                        p.aux1(v-if='props.data.cd_codigo_proprio') <b>Cód. próprio: {{ props.data.cd_codigo_proprio }}</b>
                Column(headerStyle='width:23%' bodyStyle='padding: 0' header='Status')
                    template(#body='props')
                        .cell(:class="{ on: props.data.ie_status }") {{ props.data.ie_status ? 'Agendado' : 'Pendente' }}
            
            .p-col-12(v-if='selectedProcedimento')
                label.form-label Data:
                ProgressBar(v-if="waitingDatas" mode="inderteminate" strokeWidth='6')
                p.ta-center(v-else-if='!options.datas.length' style='font-size: 12px;') <b>Sem data disponível.</b>
                div(v-else style="position:relative")
                    Calendar(
                        locale='pt-BR'
                        :first-day-of-week='-7'
                        :attributes="[ { highlight: true, dates: selectedData } ]"
                        :available-dates='options.datas'
                        @dayclick='onDayClick'
                    )
                    span(@click='selectedData=null;' v-if="selectedData" style='text-align:center;display:block;width:100%;color:#657786;cursor:pointer;font-size:.88em') limpar data
            
            .p-col-12(v-if='selectedData')
                label.form-label Horários Disponíveis:
                p.ta-center(v-if='!Object.keys(options.horarios).length' style='font-size: 12px;') <b>Sem horário disponível.</b>
                div(v-else)
                    .p-grid 
                        .p-col-6.ta-center(v-for="(item, idx) in options.horarios[selectedData_f]")
                            p
                                strong Vagas: &nbsp;
                                span.chip-turno(:class="item.nr_vagas > 0 ? 'green' : ''") {{ item.nr_vagas }} / {{ item.nr_vagas_total }}

                            p(v-if='item.nr_encaixes')
                                strong Encaixes: &nbsp;
                                span.chip-turno.purple {{ item.nr_encaixes }}

                            span
                                SelectButton.hr_horario2(
                                    class="turno"
                                    v-model='selectedHorario'
                                    :options="[item]"
                                    optionLabel='text'
                                    optionValue='value'
                                    dataKey='value'
                                    @input='vagasHorario = item.nr_vagas'
                                )
                
            .ta-center(style="margin-top:10px")
                Button.p-button-danger.mr-2(v-if="selectedHorario" type="button" label="Seguir sem agendar" @click='gerarEncaixe()' :style="{ 'width': vagasHorario ? '38%' : '100%' }")
                Button(v-if="selectedHorario && vagasHorario" type="button" label="Adicionar ao carrinho" @click='handleSubmit' style='width: 58%')

</template>

<script>
    import Dialog from 'primevue/dialog'
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Calendar from 'v-calendar/lib/components/calendar.umd'
    import Tooltip from "primevue/tooltip"
    import SelectButton from 'primevue/selectbutton'

    import { Agendamento } from '../../middleware'
    import moment from 'moment'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Dialog, SelectButton,
            InputMask, DataTable, Column, ProgressSpinner, Calendar,
        },
        directives: { tooltip: Tooltip },
        props: [
            'display',
            'procedimento',
            'model'
        ],
        computed: {
            show: {
                get() {
                    return this.display; 
                },
                set(value) { if (!value) this.$emit('close'); }
            },
        },
        mounted () {
            this.procedimento['ie_tipo_exame'] = 'I'
            this.procedimento['ie_tipo_agenda'] = this.model.ie_tipo_agenda
            if(this.model.ie_tipo_atendimento != null) this.procedimento['ie_tipo_atendimento'] = this.model.ie_tipo_atendimento
            if(this.model.ie_agenda != null) this.procedimento['ie_agenda'] = this.model.ie_agenda
            if(this.model.cd_especialidade != null) this.procedimento['cd_especialidade'] = this.model.cd_especialidade
            if(this.model.cd_especialista_solicitante) this.procedimento['cd_especialista_solicitante'] = this.model.cd_especialista_solicitante

            this.procedimento.procedimentos.map(item => item['ie_status'] = false)
        },
        data () {
            return {
                waiting: false,
                waitingDatas: false,
                waitingHorarios: false,
                submitted: false,
                options: {
                    datas: [],
                    horarios: []
                },
                selectedProcedimento:null,
                selectedData: null,
                selectedData_f: null,
                selectedHorario: null,
                vagasHorario: null,
            }
        },
        methods: {
            resetModel() {
                this.waiting = false
                this.waitingDatas = false
                this.submitted = false
                this.selectedProcedimento = null
                this.selectedData = null
                this.selectedData_f = null
                this.selectedHorario = null
                this.vagasHorario = null
                this.options.datas = []
				this.options.horarios = []
            },
            getDatas () {
				this.options.datas = []
				this.options.horarios = []
				this.selectedHorario = null
				this.selectedData = null

				let params = {
					ie_tipo_agenda: 'E',
					ie_telemedicina: this.model.ie_tipo_atendimento == 2,
					cd_estabelecimento: this.model.cd_estabelecimento,
					ie_atende_medclub_empresa: this.model.tipo_atendimento == 'E',
                    cd_procedimento: this.selectedProcedimento.id
				}

				this.waitingDatas = true
                Agendamento.getDatasAgendaLL(params).then(response => {
                    if (response.status == 200) {
                        response.data.forEach(data => {
                            this.options.datas.push(moment(data.dt_agenda)._d)
                            if (!this.options.horarios[data.dt_agenda]) {
                                this.options.horarios[data.dt_agenda] = []
                            }
                            this.options.horarios[data.dt_agenda].push({
                                value: data.id,
                                text: `De ${moment(data.hr_inicio, 'HH:mm:ss', true).format('HH:mm')} até ${moment(data.hr_fim, 'HH:mm:ss', true).format('HH:mm')}`,
                                cd_dia_atendimento: data.dt_agenda,
                                hr_inicio: data.hr_inicio,
                                hr_fim: data.hr_fim,
                                nr_vagas: data.nr_vagas,
                                nr_vagas_total: data.nr_vagas_total,
                                nr_encaixes: data.nr_encaixes,
                                disabled: !data.nr_vagas
                            })
                        })
                    }
                })
                this.waitingDatas = false
			},
            onDayClick (ev) {
				let dateChecked = false
				this.options.datas.forEach(data => { if (moment(data).format('YYYY-MM-DD') == ev.id) dateChecked = true })
				if (dateChecked) this.selectedData = moment(ev.id)._d
                this.selectedHorario = null
                this.vagasHorario = null
				this.selectedData_f = moment(this.selectedData).format('YYYY-MM-DD')
			},
            gerarEncaixe() {
                this.selectedProcedimento['ie_encaixado'] = true
                this.handleSubmit()
            },
            async handleSubmit(){
                this.selectedProcedimento['dt_agenda'] = this.selectedData
                this.selectedProcedimento['cd_horario_agendamento'] = this.selectedHorario
                this.procedimento.procedimentos = this.procedimento.procedimentos.filter(item => item.id !== this.selectedProcedimento.id)
                let dataSend = {...this.procedimento, ...this.selectedProcedimento}
                dataSend['cd_procedimento'] = this.selectedProcedimento.id
                this.$emit("agendar", dataSend)
                this.resetModel()
                if(!this.procedimento.procedimentos.length) this.$emit("close")
            }
        }, 
    }
</script>

<style lang="scss">

.dialog-agendaLL {
    width: 50%;
    .container {
        display: flex;
        flex-direction: column;
    }
    .p-dialog-content {
        padding-bottom: 6px;
    }
    .confirm-button {
        background-color: green;
    }
    .cancel-button {
        background-color: red;
    }
    .p-button.p-button-text {
        color: white;
        border-color: transparent;

        &:hover {
            background-color: transparent;
            color: black;
            border-color: black;
        }
    }
    .datatable-epecialistas {
        max-height: 400px; overflow-y: auto;
        td { font-size: 13px; }
    }
    .integracaoConsultasContainer {
        display: flex;
        .integracaoSimbolo {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .semRetornoSimbolo {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .lista-procedimentos {
        &.selecionado {
            color: blue;
        }
    }
    .text-caption {
        font-size: 16px;
        .ll{
            display: inline-block;
            margin-right:20px;
        }
        .caption {
            display: inline-block;
            height: 16px;
            width: 16px;
            background-color: #888;
            margin-right: 5px;
            border-radius: 10px;
            &.sa { background-color: #718096; }
            &.tl { background-color: #38a169; }
            &.hv { background-color: #d69e2e; }
            &.to { background-color: #f7de08; }
            &.b0 { background-color: #e53e3e; }
            &.fe { background-color: #9b67d5; }
        }
        .p-selectbutton {
            &.ie_tipo_agenda {
                .p-button { width: 25% !important; }
            }
            &.ie_tipo_atendimento {
                .p-button { width: 50% !important; }
            }
            &.hr_horario {
                .status {
                    display: inline-block;
                    vertical-align: middle;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    margin-right: 10px;
                    background-color: #38a169;
                    border: 1px solid #298051;
                    &.encaixado {
                        background-color: #9b67d5 !important;
                        border: 1px solid #6d40a0 !important;
                    }
                    &.bloqueado {
                        background-color: #e53e3e !important;
                        border: 1px solid #c73434 !important;
                    }
                    &.disabled {
                        background-color: #f7de08;
                        border: 1px solid #d3be00;
                    }
                }
                .text {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 14px;
                    font-weight: 700;
                }
                .p-button {
                    padding: 6px;
                    width: 20% !important;
                }
            }
            .p-button {
                .p-button-text {
                    font-size: 12px;
                }
            }
        }
    }
    .procedimentos-datatable {
        max-height: 500px;
        overflow-y: auto;
        .cell {
            padding: 16px 0;
            text-align: center;
            background-color: #e5e5e5;
            &.on  { background-color: #e4f8e1; }
            &.off  {
                color: #FFF;
                font-size: 16px;
                font-weight: 700;
                background-color: #ef0000;
            }
        }
        .p-highlight .cell {
            background-color: #007ad9 !important;
        }
        .cell-procedimento-checkup {
            margin: 0;
            &.strike {
                text-decoration: line-through;
            }
        }
    }
}
</style>